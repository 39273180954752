import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Form, Spin } from 'antd'
import Flex from '../../../components/Flex'
import { Col } from 'antd'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import ButtonStandard from '../../../components/Form/CustomStandardButton'
import { CustomInputPassword } from '../../../components/Form/CustomInputPassword'
import { StyledLogin, StyledLoginMenu } from '../../LoginPage/Partials/style'
import { useLocation, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { useDispatch } from 'react-redux'
import SigninImg from '../../../Images/signinimg.png'
import Logo from '../../../Images/logo.png'
import { LoadingOutlined } from '@ant-design/icons';



const ImgSection = styled.div`
margin: 20px;
  & img {
    width: 100%;
    height: auto;
  }
  & h1 {
    font-size: 40px;
    font-weight: 900;
    margin: 20px 0;
    color: #000;
  }
  & p {
    color: #000;
    font-size: 20px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`

const LogoHead = styled.div`
display: flex;
justify-content: space-between;
align-items: center;


& p {
  color: #000;
font-family: 'League Spartan';
font-size: 13.044px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;

}

& span {
  color: #000;
font-family: 'League Spartan';
font-size: 13.044px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
text-decoration-line: underline;
}

`

const LogoImg = styled.div`
margin-top: 20px;
& img {
  width: 50px;
  border: 1px solid;
}
`


const SignInForm = ({ handleSignIn, isloading }) => {

  const [check, setCheck] = useState(false)

  const location = useLocation();
  const { email, Password } = location.state || {};

  useEffect(() => {
    form.setFieldsValue({ email: email });
    form.setFieldsValue({ password: Password });
  }, []);

  const googleConfig = {
    clientId: '176349257307-8277n57npt2tie23pssv9t5g4snbnrdb.apps.googleusercontent.com',
    redirectUri: 'https://dev.hibiller.com/auth/callback/google/',
  };

  const navigate = useNavigate();


  const onChange = () => {
    setCheck(!check)
  }


  const [form] = Form.useForm();

  const Navigatee = () => {
    navigate('/register')
  }

  const handleGoogleLoginSuccess = (credentialResponse) => {
    const details = jwt_decode(credentialResponse.credential);
    const email = details.email;
    const givenname = details.given_name;


    navigate('/password', {
      state: { email, givenname },
    });
  };

  const onFinish = (values) => {
    handleSignIn(values);
  };


  const onFinishFailed = () => {
  }

  return (

    <StyledLoginMenu>

      <Form
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <CustomRow >
          <Col span={24} md={12}>
            <ImgSection>
              <Flex spacebetween={true} >
                <div>
                  <h1>WELCOME !</h1>
                  <p>TO HI BILLER ADMIN PANEL</p>
                </div>
              </Flex>
              <img src={SigninImg} alt='log' />
            </ImgSection>
          </Col>
          <Col span={24} md={12} className='bg_Right'>
            <StyledLogin>
              <Flex alignCenter={true} spaceBetween={true}>
                <LogoImg>
                  <img src={Logo} />
                </LogoImg>
                {/* <p>Not a member yet?<span onClick={Navigatee} style={{ borderBottom: '1px solid' }}>&nbsp;Create account</span></p> */}
              </Flex>
              <h1>ADMIN SIGN IN</h1>
              <CustomRow space={[12, 12]}>
                <Col span={24} md={24}>
                  <CustomInput placeholder={'Email ID'} type={'email'} name={'email'}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Email ID !',
                      }
                    ]}
                  />
                </Col>

                <Col span={24} md={24}>
                  <CustomInputPassword placeholder={'Password'} type={'password'} name={'password'}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Password !',
                      }
                    ]} />

                </Col>
                <Col span={24} md={24}>
                  {
                    isloading ?
                      <div style={{ display: 'flex',alignItems: 'center', justifyContent: 'center', height: '30px', width: '60px', marginTop: '3px',width:'100%' }}>
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 20,
                              }}
                              spin
                            />
                          }
                        />
                      </div>
                      :
                      <ButtonStandard.Primary style={{ width: '100%', padding: '20px 0', border: '0' }} text={'Sign In'} className='GGG' htmlType={'submit'} />

                  }
                </Col>
              </CustomRow>

            </StyledLogin>
          </Col>
        </CustomRow>
      </Form>

    </StyledLoginMenu>
  )
}

export default SignInForm
