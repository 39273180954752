import React, { useState } from 'react'
import Profile from "../../Images/logshow.png"
import styled from 'styled-components'
import logout from '../../Images/logout.png'
import Flex from '../../components/Flex'
import { logOut, selectCurrentUser } from '../../modules/Auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { CustomModal } from '../../components/CustomModal'
import Button from '../../components/Form/CustomButton'
import { useNavigate } from 'react-router-dom'

export const SubMenuWrap = styled.div`
  position: absolute;
  top: 100%;
  right: 10%;
  width: 300px;
  z-index: 1;
  max-height: 0px;
  
   color:var(--light-color);
 
`;

export const SubMenu = styled.div`
background-color: white;
border: 1px solid #dbcdcd;
box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
padding: 20px;
margin: 10px;

& hr {
    border: 0;
    height: 1px;
    width: 100%;
    background-color: #ccc;
    margin: 15px 0 10px;

}

`

const UserInfo = styled.div`
display: flex;
align-items: center;

& h2{
    font-weight: 900;
}

& img {
    width: 50px;
    border-radius: 50%;
    margin-right: 15px;
    border: 1px solid #dededec7;
}
`

const Submenulink = styled.div`
display: flex;
align-items: center;
text-decoration: none;
color: #525252;
margin: 12px 0;

& p {
    width: 100%;
}

& p:hover {
    font-size: 15px;
    cursor: pointer;
  }


& img {
    width: 40px;
    background-color: #e5e5e5;
    border-radius: 50%;
    padding: 8px;
    margin-right: 15px;
    cursor: pointer;

}

& span {
    font-size: 22px;
}


`

const ProfileStyle = () => {

    // const ProfileName = useSelector(selectCurrentUser)

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const dispatch = useDispatch();

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const AdminLogOut = () => {
        setModalContent(<LogOutModal />);
        setModalTitle("Log Out");
        showModal();
    }
    const ProfileName = useSelector(selectCurrentUser)

    const LogOutModal = () => (
        <div>
            <h1 style={{ fontSize: '1.2rem' }}>Are you Sure You Want to Logout ?</h1>
            <br />
            <Flex gap={'20px'} W_100 center verticallyCenter>
                <Button.Success text={'Logout'} onClick={Signout} />
                <Button.Danger text={'Cancel'} onClick={handleOk} />
            </Flex>
        </div>
    )

    const Signout = () => {
        dispatch(logOut());
        localStorage.removeItem('persist')
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const navigate = useNavigate()
    
    const handleClick = () => {
        navigate('/addprofile');
    };



    return (
        <SubMenuWrap>
            <SubMenu>
                <UserInfo>

                    <img src={Profile} alt="Profile" preview={false} />

                    <h2>{ProfileName}</h2>
                </UserInfo>
                <hr></hr>

                {/* <Submenulink href='#' >
                    <img src={profiles} />
                    <p onClick={handleClick}>Business Profile</p>
                </Submenulink> */}

                <Submenulink href='#' className='submenulink'>
                    <img src={logout} />
                    <p onClick={AdminLogOut} >Logout</p>
                </Submenulink>

            </SubMenu>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                width={400}
                modalTitle={modalTitle} modalContent={modalContent} />
        </SubMenuWrap>
    )
}

export default ProfileStyle
