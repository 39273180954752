import PageNotFound from '../components/PageNotFound'
import UserSignin from "../../modules/Auth/Components/UserSignin";
import RegisterMenu from '../../modules/LoginPage/Partials/RegisterMenu';
import PasswordForm from '../../modules/Auth/Components/PasswordForm';
import { NetWorkError } from '../../components/NetWorkError';
import { AddPlanType } from '../../modules/AdminPanel/Partials/AddPlanType';
import { ViewPlanType } from '../../modules/AdminPanel/Partials/ViewPlanType';
import { ViewPlan } from '../../modules/AdminPanel/Partials/ViewPlan';
import { AddPlan } from '../../modules/AdminPanel/Partials/AddPlan';
import FreeTrialStatus from '../../modules/FreeTrial';
import UserView from '../../modules/UserView/Partials/UserView';
import WelcomePage from '../../modules/AdminPanel/Partials/WelcomePage';
import { EcomUserView } from '../../modules/UserView/Partials/EcomUserView';
import { PaymentRecord } from '../../modules/UserView/Partials/PaymentRecord';
import { AddAdmin } from '../../modules/AdminPanel/Partials/AddAdmin';
import { ViewAdmin } from '../../modules/AdminPanel/Partials/ViewAdmin';




export const anonymous = [
    {
        routePath: '/signin',
        Component: UserSignin,
    },
    {
        routePath: '/register',
        Component: RegisterMenu,
    },
    {
        routePath: '/password',
        Component: PasswordForm,
    },
]

export const adminAuthenticated = [

    {       // ----------- Page Not Fonund
        routePath: '*',
        Component: PageNotFound,
    },
    {       // ----------- Network Error
        routePath: 'networkerror',
        Component: NetWorkError,
    },  // ----------- Admin Panel

    {     
        routePath: 'add_plan_type',
        Component: AddPlanType,
    },
    {     
        routePath: 'view_plan_type',
        Component: ViewPlanType,
    },
    {     
        routePath: 'add_plan',
        Component: AddPlan,
    },
    {     
        routePath: 'view_plan',
        Component: ViewPlan,
    },
    {     
        routePath: 'freetrial',
        Component: FreeTrialStatus,
    },
    {     
        routePath: 'user_view',
        Component: UserView,
    },
    {     
        routePath: 'ecom_user_view',
        Component: EcomUserView,
    },
    {     
        routePath: 'paymentRecord/:id',
        Component: PaymentRecord,
    },
    {     
        routePath: 'add_admin',
        Component: AddAdmin,
    },
    {     
        routePath: 'view_admin',
        Component: ViewAdmin,
    },
    {     
        routePath: '',
        Component: WelcomePage,
    },

]