import React from 'react'
import Flex from '../../../components/Flex';
import { FiEdit } from 'react-icons/fi';
import { TableIconHolder } from '../../../components/CommonStyled';
import { THEME } from '../../../theme';
import { CustomPopconfirm } from '../../../components/CustomPopConfirm';
import { MdDelete } from 'react-icons/md';
import { CommonLoading } from '../../../components/CommonLoading';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  getAllPlanStatus, getAllPlans, getPlan,  } from '../PanelSlice';
import { CustomTable } from '../../../components/Form/CustomTable';
import { useState } from 'react';
import { CustomCardView } from '../../../components/CustomCardView';
import { CustomPageFormTitle } from '../../../components/CustomPageTitle';
import { CustomModal } from '../../../components/CustomModal';
import { toast } from 'react-toastify';
import request from '../../../utils/request';
import { AddPlan } from './AddPlan';
import { Popconfirm } from 'antd';
import { HiOutlineBellAlert, HiOutlineBellSlash } from 'react-icons/hi2';

export const ViewPlan = () => {

    const [dataSource, setDataSource] = useState([])
    const [trigger, setTrigger] = useState(0)
    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const PLAN_DEL = 'subscription/edit_plans'
    const dispatch = useDispatch()

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // ===== Modal Functions End =====

    const onPlanEdit = (record) => {
        setTrigger(trigger + 1)
        setModalContent(<AddPlan updateplanrecord={record} Formcancel={handleOk} Trigger={trigger} formname={'UpdatePlanForm'} />);
        showModal();
    };

    const handleConfirm = (record) => {
        DeletePlan(record);
    }

    const DeletePlan = (record) => {
        request.delete(`${PLAN_DEL}/${record?.id}/`)
            .then((response) => {
                dispatch(getPlan())
                toast.info("Plan Deleted Successfully")
            }).catch(error => {
                toast.error("Something Went Wrong")
            });
    };

    useEffect(() => {
        dispatch(getPlan())
    }, [])

    const getPlansDetails = useSelector(getAllPlans)
    const getPlansStatusDetails = useSelector(getAllPlanStatus)

    useEffect(() => {
        setDataSource(getPlansDetails)
    }, [getPlansDetails])

    const InActivePlan = (record) => {
        request.patch(`subscription/deactivating_plans/${record?.id}/`)
            .then(function (response) {
                dispatch(getPlan());
            })
            .catch(function (error) {
            });
    }

    const ActivePlan = (record) => {
        request.patch(`subscription/activating_plans/${record?.id}/`)
            .then(function (response) {
                dispatch(getPlan());
            })
            .catch(function (error) {
            });
    }

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Plan Type',
            dataIndex: 'sub_plan_type',
        },
        {
            title: 'Planning Choice',
            dataIndex: 'planing_choice',
        },
        {
            title: 'Planning Domain',
            dataIndex: 'planing_domine',
        },
        {
            title: 'Plan Amount',
            dataIndex: 'plan_amount',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => { onPlanEdit(record) }}>
                            <FiEdit />
                        </TableIconHolder>
                        {
                            record.status === 'ACTIVE' &&
                            <Popconfirm
                                title="Change The Status"
                                description="Do you want to change the status into 'IN-ACTIVE'?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => InActivePlan(record)}
                            >
                                <TableIconHolder color={THEME.GREEN_100} size={'22px'}>
                                    <HiOutlineBellAlert />
                                </TableIconHolder>
                            </Popconfirm>
                        }
                        {
                            record.status === 'DEACTIVE' &&
                            <Popconfirm
                                title="Change The Status"
                                description="Do you want to change the status into 'ACTIVE'?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => ActivePlan(record)}
                            >
                                <TableIconHolder color={THEME.RED_100} size={'22px'}>
                                    <HiOutlineBellSlash />
                                </TableIconHolder>
                            </Popconfirm>
                        }

                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            title={"Delete the Product"}
                            description={"Are you sure to delete this Plan ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )
            }
        },
    ];

    let content;

    if (getPlansStatusDetails === 'loading') {
        content = <CommonLoading />
    } else if (getPlansStatusDetails === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (getPlansStatusDetails === 'failed') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    }

    return (
        <div>
            <CustomPageFormTitle Heading={'View Plans'} />
            <CustomCardView>
                {content}
            </CustomCardView>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}
