import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    jwt: null,
    username: null,
}
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { username, jwt } = action.payload
            state.username = username
            state.jwt = jwt
        },
        logOut: (state, action) => {
            state.username = null
            state.jwt = null
        }
    }
})

export const { setCredentials, logOut } = authSlice.actions
export const selectCurrentUser = (state) => state.auth.username
export const selectCurrentJwt = (state) => state.auth.jwt
export default authSlice.reducer