import { Col, Form } from 'antd'
import React, { useState } from 'react'
import Flex from '../../../components/Flex'
import Button from '../../../components/Form/CustomButton'
import { useEffect } from 'react'
import request from '../../../utils/request'
import { toast } from 'react-toastify'
import { CustomRow } from '../../../components/CustomRow'
import { CustomCardView } from '../../../components/CustomCardView'
import { CustomPageFormTitle } from '../../../components/CustomPageTitle'
import { CustomInputNumber } from '../../../components/Form/CustomInputNumber'
import { getPlanType } from '../PanelSlice'
import { useDispatch } from 'react-redux'
import { CustomSelect } from '../../../components/Form/CustomSelect'

export const AddPlanType = ({ updateplanrecord, Trigger, Formcancel }) => {

    const [form] = Form.useForm()

    const [optionSelect, setOptionSelect] = useState({})

    const ADD_PLAN_TYPE = 'subscription/add_plans_type/'
    const EDIT_PLAN_TYPE = 'subscription/edit_plans_type'
    const dispatch = useDispatch()

    const onReset = () => {
        form.resetFields();
    }

    const onFinish = (values) => {

        if (updateplanrecord) {
            UpdatePlanType(updateplanrecord.id, values)
        }
        else {
            AddPlanTypePost(values)
        }
    };

    const onFinishFailed = (errorInfo) => {
        toast.error('Fill the details')
    };

    useEffect(() => {
        form.resetFields();
    }, [Trigger])

    useEffect(() => {
        form.setFieldsValue(updateplanrecord)
    }, [updateplanrecord, Trigger])

    const AddPlanTypePost = (values) => {
        request.post(`${ADD_PLAN_TYPE}`, values)
            .then(function (response) {
                if (response.status == 201) {
                    dispatch(getPlanType())
                    form.resetFields();
                    toast.success('Plan Type Added Successfully')
                } else if (response.status == 226) {
                    toast.warn('Data already exists')
                } else {
                    toast.error('Failed')
                }
            })
            .catch(function (error) {
                console.log(error.response);
            });
    }

    const UpdatePlanType = (id, values) => {
        request.put(`${EDIT_PLAN_TYPE}/${id}/`, values)
            .then(function (response) {
                dispatch(getPlanType())
                form.resetFields();
                Formcancel()
                toast.info('Plan Type Updated Successfully')

                if (response.status == 226) {
                    toast.warn('Data already exists')
                } else {
                    toast.error('Failed')
                }
            })
            .catch(function (error) {
            });
    }

    const PlanOptions = [
        {
            label: 'Free',
            value: 'Free'
        },
        {
            label: '1 Year',
            value: '1 Year'
        },
        {
            label: '2 Year',
            value: '2 Year'
        },
        {
            label: '3 Year',
            value: '3 Year'
        },
        {
            label: '4 Year',
            value: '4 Year'
        },
        {
            label: '5 Year',
            value: '5 Year'
        }
    ]

    const HandleChangs = (value) => {
        setOptionSelect(value)

        const free = '15';
        const oneYearValue = '365';
        const twoYearValue = '730';
        const threeYearValue = '1095';
        const fourYearValue = '1460';
        const fiveYearValue = '1825';

        let durationValue;

        switch (value) {
            case 'Free':
                durationValue = free;
                break;
            case '1 Year':
                durationValue = oneYearValue;
                break;
            case '2 Year':
                durationValue = twoYearValue;
                break;
            case '3 Year':
                durationValue = threeYearValue;
                break;
            case '4 Year':
                durationValue = fourYearValue;
                break;
            case '5 Year':
                durationValue = fiveYearValue;
                break;
            default:
                durationValue = '';
        }

        form.setFieldsValue({
            duration: durationValue,
        });
    };


    return (
        <div>
            {
                updateplanrecord ? (<> <CustomPageFormTitle Heading={'Update Plan Type'} /></>) : (<> <CustomPageFormTitle Heading={'Add Plan Type'} /></>)
            }

            <CustomCardView>
                <Form
                    name='AddPlanType'
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">
                    <CustomRow space={[24, 24]}>

                        <Col span={24} md={12}>
                            <CustomSelect label={'Plan'} options={PlanOptions} onChange={HandleChangs} placeholder={'Select Plan'} name={'plans'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Plan!',
                                    }
                                ]}
                            />
                        </Col>

                        <Col span={24} md={12}>
                            <CustomInputNumber label={'Duration [ in days ]'} placeholder={'Enter Duration'} name={'duration'} disabled={true}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Duration!',
                                    }
                                ]}
                            />
                        </Col>
                    </CustomRow>
                    <Flex center gap={'20px'} style={{ margin: '30px' }}>
                        {
                            updateplanrecord ? (<> <Button.Primary text={'UPDATE'} htmlType={'submit'} /></>)
                                :
                                (<>
                                    <Button.Success text={'ADD'} htmlType={'submit'} />
                                    <Button.Danger text={'RESET'} onClick={onReset} />
                                </>)
                        }
                    </Flex>
                </Form>
            </CustomCardView>
        </div>
    )
}
