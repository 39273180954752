import React, { useState } from 'react'
import { StyledLoginMenu, StyledRegisterMenu } from './style'
import { StyledLogin } from './style'
import { Col, Form, Spin } from 'antd'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import Flex from '../../../components/Flex'
import ButtonStandard from '../../../components/Form/CustomStandardButton'
import { CustomInputPassword } from '../../../components/Form/CustomInputPassword'
import { toast } from 'react-toastify'
import { baseRequest } from '../../../utils/request'
import { useNavigate } from 'react-router-dom'
import SigninImg from '../../../Images/signinimg.png'
import styled from 'styled-components'
import Logo from '../../../Images/logo.png'
import { LoadingOutlined } from '@ant-design/icons';




const ImgSection = styled.div`
margin: 20px;
  & img {
    width: 100%;
    height: auto;
  }
  & h1 {
    font-size: 40px;
    font-weight: 900;
    margin: 20px 0;
    color: #000;
  }
  & p {
    color: #000;
    font-size: 20px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`

const LogoImg = styled.div`
    margin-top: 20px;
& img {
  width: 50px;
  border: 1px solid;
}
`

const RegisterMenu = () => {

    const [form] = Form.useForm()

    const URLS = 'user/admin_register/'

    const [check, setCheck] = useState(false)

    const [isloading, setIsloading] = useState(false);

    const navigate = useNavigate()

    const Navigatee = () => {
        navigate('/signin')
    }

    const onChange = () => {
        setCheck(!check)
    }

    const onFinish = values => {

        const registerValues = {
            name: values?.name,
            email: values?.email,
            password: values?.password,
            referal_code: values?.referal_code || null
        }
        HandlePostPerson(registerValues)

    }
    const HandlePostPerson = (values) => {
        baseRequest.post(`${URLS}`, values).then((response) => {
            if (response.status === 201) {
                form.resetFields();
                toast.success('Added Successfully')
                navigate('/signin')
                setIsloading(true)
            }
            else {
                // toast.warn('Email Id Already Exits')
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data) {
                        toast.warn(error.response.data?.email[0]);
                    }
                }
                else if (error.response.status === 303) {
                    toast.warn(error.response.data?.message);
                }
                else {
                    toast.error('Something Went Wrong');
                    setIsloading(false)
                }
                setIsloading(false)
            }
            else if (error.request) {
                toast.error('Request Error');
            } else {
                toast.error('Connection Error');
            }
        });
    }

    const onFinishFailed = () => {
    }

    return (
        <StyledLoginMenu style={{ background: '#e1e1e145' }}>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <CustomRow>
                    <Col span={24} md={12}>
                        <ImgSection>
                            <Flex spacebetween={true} >
                                <div>
                                    <h1>WELCOME !</h1>
                                    <p>TO HI BILLER ADMIN PANEL</p>
                                </div>
                            </Flex>
                            <img src={SigninImg} alt='log' />
                        </ImgSection>
                    </Col>
                    <Col span={24} md={12} className='bg_Right'>
                        <StyledLogin>
                            <Flex alignCenter={true} spaceBetween={true}>
                                <LogoImg>
                                    <img src={Logo} />
                                </LogoImg>
                                <p>Cancel, Go Back ? <span onClick={Navigatee} style={{ borderBottom: '1px solid' }}>&nbsp;Sign In</span></p>
                            </Flex>
                            <h1>ADMIN REGISTER</h1>
                            <CustomRow space={[12, 12]}>
                                <Col span={24} md={24}>
                                    <CustomInput placeholder={'Name'} name={'name'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Name !',
                                            }
                                        ]}
                                    />
                                </Col>

                                <Col span={24} md={24}>
                                    <CustomInput placeholder={'Email ID'} type={'email'} name={'email'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Email ID !',
                                            }
                                        ]}
                                    />
                                </Col>

                                <Col span={24} md={24}>
                                    <CustomInputPassword placeholder={'Password'} type={'password'} name={'password'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Password!',
                                            }
                                        ]} />

                                </Col>
                                <Col span={24} md={24}></Col>

                                <Col span={24} md={24}>
                                    {
                                        isloading ?
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px', width: '60px', marginTop: '3px' }}>
                                                <Spin
                                                    indicator={
                                                        <LoadingOutlined
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                            spin
                                                        />
                                                    }
                                                />
                                            </div>
                                            :
                                            <ButtonStandard.Primary style={{ width: '100%', padding: '20px 0', border: '0' }} text={'Sign In'} className='GGG' htmlType={'submit'} />

                                    }
                                </Col>


                            </CustomRow>
                        </StyledLogin>
                    </Col>
                </CustomRow>
            </Form>
        </StyledLoginMenu>
    )
}

export default RegisterMenu