import React, { useState, useEffect } from 'react';
import { Col, Form } from 'antd';
import Flex from '../../../components/Flex';
import Button from '../../../components/Form/CustomButton';
import request from '../../../utils/request';
import { toast } from 'react-toastify';
import { CustomRow } from '../../../components/CustomRow';
import { CustomInput } from '../../../components/Form/CustomInput';
import { CustomCardView } from '../../../components/CustomCardView';
import { CustomPageFormTitle } from '../../../components/CustomPageTitle';
import { CustomInputNumber } from '../../../components/Form/CustomInputNumber';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSelect } from '../../../components/Form/CustomSelect';
import { getAllPlanType, getPlan, getPlanType } from '../PanelSlice';

export const AddPlan = ({ updateplanrecord, Trigger, Formcancel }) => {

    const [form] = Form.useForm();
    const [selectedPlanningChoice, setSelectedPlanningChoice] = useState('');
    const ADD_PLAN = 'subscription/add_plans/';
    const EDIT_PLAN = 'subscription/edit_plans';
    const dispatch = useDispatch();

    const domainchoice = [
        {
            label: 'MOBILE',
            value: 'MOBILE',
        },
        {
            label: 'DESKTOP',
            value: 'DESKTOP',
        },
        {
            label: 'WEBAPP',
            value: 'WEBAPP',
        },
        {
            label: 'SMS',
            value: 'SMS',
        },
        {
            label: 'WHATSAPP',
            value: 'WHATSAPP',
        },
        {
            label: 'PAYMENTGATEWAY',
            value: 'PAYMENTGATEWAY',
        },
    ];

    const planningchoice = [
        {
            label: 'GST BILLING',
            value: 'GST BILLING',
        },
        {
            label: 'ECOMMERCE',
            value: 'ECOMMERCE',
        },
        {
            label: 'OTHER',
            value: 'OTHER',
        },
    ];

    const secondSelectOptions = selectedPlanningChoice === 'GST BILLING'
        ? [
            'MOBILE',
            'WEBAPP',
            'DESKTOP',
        ]
        : selectedPlanningChoice === 'ECOMMERCE'
            ? [
                'MOBILE',
                'WEBAPP',
            ]
            : [
                'SMS',
                'WHATSAPP',
                'PAYMENTGATEWAY',
            ];

    const onReset = () => {
        form.resetFields();
    };

    const onFinish = (values) => {
        if (updateplanrecord) {
            UpdatePlan(updateplanrecord.id, values);
        } else {
            AddPlanPost(values);
        }
    };

    const onFinishFailed = (errorInfo) => {
        toast.error('Fill the details')
    };

    useEffect(() => {
        form.resetFields();
    }, [Trigger]);

    useEffect(() => {
        dispatch(getPlanType());
    }, [dispatch]);

    const PlanTypeDetails = useSelector(getAllPlanType);

    const plantypechoice = PlanTypeDetails?.map((plan) => ({
        label: plan.plans,
        value: plan.plans,
    }));

    const handlePlanningChoice = (value) => {
        setSelectedPlanningChoice(value);
    };

    useEffect(() => {
        form.setFieldsValue(updateplanrecord);
    }, [updateplanrecord, Trigger]);

    const AddPlanPost = (values) => {
        request.post(ADD_PLAN, values)
            .then(function (response) {
                if (response.status === 201) {
                    dispatch(getPlan());
                    form.resetFields();
                    toast.success('Plan Added Successfully');
                } else if (response.status == 226) {
                    toast.warn('Data already exists')
                } else {
                    toast.error('Failed')
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const UpdatePlan = (id, values) => {
        request.put(`${EDIT_PLAN}/${id}/`, values)
            .then(function (response) {
                dispatch(getPlan());
                form.resetFields();
                Formcancel();
                toast.info('Plan Updated Successfully');
                if (response.status == 226) {
                    toast.warn('Data already exists')
                } else {
                    toast.error('Failed')
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    return (
        <div>
            <CustomPageFormTitle Heading={updateplanrecord ? 'Update Plan' : 'Add Plan'} />

            <CustomCardView>
                <Form
                    name='AddPlan'
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">

                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={12}>
                            {
                                updateplanrecord ? (<CustomInput
                                    label={'Plan'}
                                    placeholder={'Select Plan'}
                                    name={'sub_plan_type'}
                                    options={plantypechoice}
                                    disabled={'true'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Plan!',
                                        },
                                    ]} />
                                ) : (
                                    <CustomSelect
                                        label={'Plan'}
                                        placeholder={'Select Plan'}
                                        name={'sub_plan_type'}
                                        options={plantypechoice}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select Plan!',
                                            },
                                        ]}
                                    />
                                )
                            }

                        </Col>
                        <Col span={24} md={12}>
                            {
                                updateplanrecord ? (<CustomInput
                                    label={'Planning Choice'}
                                    placeholder={'Select Planning Choice'}
                                    name={'planing_choice'}
                                    options={planningchoice}
                                    onChange={handlePlanningChoice}
                                    disabled={'true'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Planning Choice!',
                                        },
                                    ]}
                                />) : (<CustomSelect
                                    label={'Planning Choice'}
                                    placeholder={'Select Planning Choice'}
                                    name={'planing_choice'}
                                    options={planningchoice}
                                    onChange={handlePlanningChoice}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Planning Choice!',
                                        },
                                    ]}
                                />)
                            }

                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputNumber
                                label={'Plan Amount'}
                                placeholder={'Enter Plan Amount'}
                                name={'plan_amount'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Plan Amount!',
                                    },
                                ]}
                            />
                        </Col>

                        {
                            updateplanrecord ? (<Col span={24} md={12}>
                                <CustomInput
                                    label={'Planning Domain'}
                                    placeholder={'Select Planning Domain'}
                                    name={'planing_domine'}
                                    options={domainchoice}
                                    disabled={'true'}
                                />
                            </Col>) : ('')
                        }

                        <Col span={24} md={12}>
                            {selectedPlanningChoice && (
                                <CustomSelect
                                    label={'Planning Domain'}
                                    placeholder={'Select Planning Domain'}
                                    name={'planing_domine'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Planning Domain!',
                                        },]}
                                    options={secondSelectOptions.map(option => ({
                                        label: option,
                                        value: option,
                                    }))}
                                />
                            )}
                        </Col>

                    </CustomRow>
                    <Flex center gap={'20px'} style={{ margin: '30px' }}>
                        {updateplanrecord ? (
                            <Button.Primary text={'UPDATE'} htmlType={'submit'} />
                        ) : (
                            <>
                                <Button.Success text={'ADD'} htmlType={'submit'} />
                                <Button.Danger text={'RESET'} onClick={onReset} />
                            </>
                        )}
                    </Flex>
                </Form>
            </CustomCardView>
        </div>
    );
};




