import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Flex from '../../../components/Flex';
import { TableIconHolder } from '../../../components/CommonStyled';
import { THEME } from '../../../theme';
import { CustomPageFormTitle } from '../../../components/CustomPageTitle';
import { CustomModal } from '../../../components/CustomModal';
import { FreePlantatus, getAdmins, getAllAdmins } from '../PanelSlice';
import { CustomTable } from '../../../components/Form/CustomTable';
import { CommonLoading } from '../../../components/CommonLoading';
import { CustomCardView } from '../../../components/CustomCardView';
import { AiFillLock, AiFillUnlock } from 'react-icons/ai';
import request from '../../../utils/request';
import { CustomTag } from '../../../components/Form/CustomTag';

export const ViewAdmin = () => {

  const [dataSource, setDataSource] = useState([])

  const dispatch = useDispatch()

  const URLE = 'user/activating_user'
  const URLD = 'user/deactivating_user'

  useEffect(() => {
    dispatch(getAdmins())
  }, [])

  const getAdminDetails = useSelector(getAllAdmins)
  const getAdminStatus = useSelector(FreePlantatus)


  useEffect(() => {
    setDataSource(getAdminDetails)
  }, [getAdminDetails])

  const handleChange = (values) => {
      if (values.status === 'Disabled') {
        request.put(`${URLE}/${values.id}/`)
            .then(function (response) {
                dispatch(getAdmins())
            })
            .catch(function (error) {
                console.error(error);
            });
    }
    else {
        request.put(`${URLD}/${values.id}/`)
            .then(function (response) {
                dispatch(getAdmins())
            })
            .catch(function (error) {
                console.error(error);
            });
    }
  }

  const columns = [
    {
      title: 'Sl No',
      render: (item, value, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, i) => {
        const displayStatus = status === 'Enabled' ? 'Enabled' : 'Disabled';
        return (
            <>
                <CustomTag bordered={true} color={status === 'Enabled' ? 'success' : 'error'} title={displayStatus} />
            </>
        )   
    },
    },
    {
      title: 'User Role',
      dataIndex: 'user_role',
    },
    {
      title: 'Action',
      render: (record) => {
        return (
          <Flex center={"true"} gap={'10px'}>

            {record?.status === 'Enabled' ?
              <TableIconHolder color={THEME.secondary_color_light} size={'22px'}>
                <AiFillUnlock onClick={() => handleChange(record)} />
              </TableIconHolder> :
              <TableIconHolder color={THEME.green} size={'22px'}>
                <AiFillLock onClick={() => handleChange(record)} />
              </TableIconHolder>}
          </Flex>
        )
      }
    },
  ];


  let content;

  if (getAdminStatus === 'loading') {
    content = <CommonLoading />
  } else if (getAdminStatus === 'succeeded') {
    const rowKey = (dataSource) => dataSource.id;
    content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
  } else if (getAdminStatus === 'failed') {
    const rowKey = (dataSource) => dataSource.id;
    content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
  }

  return (
    <div>
      <CustomPageFormTitle Heading={'View Admin'} />
      <CustomCardView>
        {content}
      </CustomCardView>
    </div>
  )
}
