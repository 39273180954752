import { Card, Dropdown, Menu } from "antd";
import styled from "styled-components";

export const StyledNotification = styled.div`

/* background-color: red; */
& img{
    margin: auto;
    border-radius: 50%;
    width: 40px;
}
 /* width: 250px; */
 width: auto;
& h6{
color: #441f69;
}

& p{
color: blueviolet
}

& span{
    color: #6e0e46;
}
`

export const StyledNotificationCard = styled(Card) `

background-color: #f5faf6;
box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
transition: ease;
/* transform: scale(-2); */

`
export const SyledDropDownMenu = styled(Dropdown)`

/* width: 200px; */
/* background-color: red; */
/* box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2); */
`

