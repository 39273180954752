import { Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { AiFillLock, AiFillUnlock } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CommonLoading } from '../../../components/CommonLoading'
import { TableIconHolder } from '../../../components/CommonStyled'
import { CustomModal } from '../../../components/CustomModal'
import { CustomPageFormTitle } from '../../../components/CustomPageTitle'
import Flex from '../../../components/Flex'
import { CustomTable } from '../../../components/Form/CustomTable'
import { THEME } from '../../../theme'
import request from '../../../utils/request'
import { getUser, ViewError, ViewStatus, ViewTableuser } from '../UserSlice'
import { CustomTag } from '../../../components/Form/CustomTag'
import { FaFileInvoiceDollar } from "react-icons/fa6";

const UserView = () => {

    const [dataSource, setDataSource] = useState([])

    const DEL_PARTY_URL = 'product/edit_unit'

    const URLE = 'user/activating_user'
    const URLD = 'user/deactivating_user'

    const navigate = useNavigate();

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);


    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);
    const [profileFormUpdate, setProfileFormUpdate] = useState(0)
    const [trigger, setTrigger] = useState(0)


    const dispatch = useDispatch()

    const ViewUnitTableData = useSelector(ViewTableuser)
    const GetUnitStatus = useSelector(ViewStatus)


    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        FormRest()

    };

    const handleCancel = () => {
        setIsModalOpen(false);

    };
    const FormUpdate = () => {
        setProfileFormUpdate(profileFormUpdate + 1)
    }

    const handleGetUnit = () => {
        dispatch(getUser())

    }

    const FormRest = () => {
        setFormReset(formReset + 1)
    }

    // ===== Modal Functions End =====

    const UpdateUnit = (record) => {
        setTrigger(trigger + 1)
        setModelwith(500)
        setModalTitle("Update Unit");
        showModal();
    };

    // ========== Delete post ==========

    const handleConfirm = (record) => {
        FormUpdate()
        DeleteUnit(record)
    }

    const handleChage = (record) => {
        Getpatch(record);
    };

    const Getpatch = (values) => {
        if (values.status === 'Disabled') {
            request.put(`${URLE}/${values.id}/`)
                .then(function (response) {
                    dispatch(getUser())
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
        else {
            request.put(`${URLD}/${values.id}/`)
                .then(function (response) {
                    dispatch(getUser())
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    }


    const DeleteUnit = (record) => {
        request.delete(`${DEL_PARTY_URL}/${record?.id}/`)
            .then((response) => {
                // dispatch(getUnit())
                toast.info("Deleted Successfully")
            }).catch(error => {
                if (error.response.status === 400) {
                    toast.error("Something Went Wrong")
                } else {
                    toast.error("Failed")
                }
            });
    };

    const ViewPaymentRecord = (record) => {
        navigate(`/paymentRecord/${record.id}`)
    }

    const columns = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile_number'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status, i) => {
                const displayStatus = status === 'Enabled' ? 'Enabled' : 'Disabled';
                return (
                    <>
                        <CustomTag bordered={true} color={status === 'Enabled' ? 'success' : 'error'} title={displayStatus} />
                    </>
                )   
            },
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex spaceEvenly>
                        {record?.status === 'Enabled' ?
                            <TableIconHolder color={THEME.secondary_color_light} size={'22px'}>
                                <AiFillUnlock onClick={() => handleChage(record)} />
                            </TableIconHolder> :
                            <TableIconHolder color={THEME.green} size={'22px'}>
                                <AiFillLock onClick={() => handleChage(record)} />
                            </TableIconHolder>}
                        <TableIconHolder color={THEME.yellow} size={'22px'}>
                            <FaFileInvoiceDollar onClick={() => ViewPaymentRecord(record)} />
                        </TableIconHolder>
                    </Flex>
                )

            }
        },
    ]
    useEffect(() => {
        setDataSource(ViewUnitTableData)
    }, [ViewUnitTableData])

    useEffect(() => {
        dispatch(getUser())
    }, [profileFormUpdate])


    let content;

    if (GetUnitStatus === 'loading') {
        content = <CommonLoading />
    } else if (GetUnitStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (GetUnitStatus === 'failed') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={[{}]} rowKey={rowKey} />
    }

    return (
        <div>
            <CustomPageFormTitle Heading={'View Users'} />
            {content}
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />

        </div>
    )
}

export default UserView