import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { adminAuthenticated } from '../config/user'
import Flex from '../../components/Flex'
import styled from 'styled-components'
import { DashLayout } from '../../layout/DashLayout'
import { useDispatch, useSelector } from 'react-redux'


const PageFlex = styled(Flex)`
  overflow: hidden;
`
const AuthPage = ({ isAuthenticated }) => {

    const dispatch = useDispatch()


    const navigate = useNavigate()


    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/signin')
        }
        
    }, [isAuthenticated])

    return (
        <PageFlex>
            {isAuthenticated && (
                <DashLayout>
                    <Routes>
                        {adminAuthenticated.map(({ routePath, Component }) => {
                            return (
                                <Route
                                    key={routePath}
                                    path={routePath}
                                    element={<Component />}
                                ></Route>
                            )
                        })}
                    </Routes>
                </DashLayout>
            )}
        </PageFlex>
    )
}

export default AuthPage
