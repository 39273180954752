import React from 'react'
import TableDatas from './Partials/TableDatas'
import { CustomPageFormTitle } from '../../components/CustomPageTitle'

const FreeTrialStatus = () => {
  return (
    <div>
        <CustomPageFormTitle Heading={'Trial Status'} /> 
        <TableDatas />
    </div>
  )
}

export default FreeTrialStatus