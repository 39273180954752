import React from 'react'
import styled from 'styled-components'

const StyleMain = styled.div`
    margin: auto;
    display: flex;
    align-items: center;
& h1 {
    font-size:60px;
    margin: auto;
}
@media screen and (max-width: 500px){
    & h1 {
    font-size:20px;
    margin: auto;
}
}
`

const WelcomePage = () => {
    return (
        <StyleMain>
            <h1>Welcome</h1>
        </StyleMain>
    )
}

export default WelcomePage