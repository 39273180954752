import React from 'react'
import Flex from '../../../components/Flex';
import { FiEdit } from 'react-icons/fi';
import { TableIconHolder } from '../../../components/CommonStyled';
import { THEME } from '../../../theme';
import { CustomPopconfirm } from '../../../components/CustomPopConfirm';
import { MdDelete } from 'react-icons/md';
import { CommonLoading } from '../../../components/CommonLoading';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPlanType, getAllPlanTypeError, getAllPlanTypeStatus, getPlanType } from '../PanelSlice';
import { CustomTable } from '../../../components/Form/CustomTable';
import { useState } from 'react';
import { CustomCardView } from '../../../components/CustomCardView';
import { CustomPageFormTitle } from '../../../components/CustomPageTitle';
import { AddPlanType } from './AddPlanType';
import { CustomModal } from '../../../components/CustomModal';
import { toast } from 'react-toastify';
import request from '../../../utils/request';
import { HiOutlineBellAlert, HiOutlineBellSlash } from "react-icons/hi2";
import { Popconfirm } from 'antd';

export const ViewPlanType = () => {

    const dispatch = useDispatch()

    const [dataSource, setDataSource] = useState([])
    const [trigger, setTrigger] = useState(0)

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const DEL_PLAN_TYPE = 'subscription/edit_plans_type'

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // ===== Modal Functions End =====

    const onPlanTypeEdit = (record) => {
        setTrigger(trigger + 1)
        setModalContent(<AddPlanType updateplanrecord={record} Formcancel={handleOk} Trigger={trigger} formname={'UpdatePlanTypeForm'} />);
        showModal();
    };

    const handleConfirm = (record) => {
        DeletePlanType(record);
    }

    const DeletePlanType = (record) => {
        request.delete(`${DEL_PLAN_TYPE}/${record?.id}/`)
            .then((response) => {
                dispatch(getPlanType())
                toast.info("Plan Type Deleted Successfully")
            }).catch(error => {
                toast.error("Something Went Wrong")
            });
    };


    useEffect(() => {
        dispatch(getPlanType())
    }, [])

    const getPlanTypeDetails = useSelector(getAllPlanType)
    const getPlanTypeStatusDetails = useSelector(getAllPlanTypeStatus)
    const getPlanTypeErrorDetails = useSelector(getAllPlanTypeError)

    useEffect(() => {
        setDataSource(getPlanTypeDetails)
    }, [getPlanTypeDetails])

    const InActivePlanType = (record) => {

        request
            .patch(`subscription/deactivating_plan_type/${record?.id}/`)
            .then(function (response) {
                dispatch(getPlanType());
            })
            .catch(function (error) {
            });
    }

    const ActivePlanType = (record) => {

        request
            .patch(`subscription/activating_plan_type/${record?.id}/`)
            .then(function (response) {
                dispatch(getPlanType());
            })
            .catch(function (error) {
            });
    }

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Plan',
            dataIndex: 'plans',
        },
        {
            title: 'Duration in days',
            dataIndex: 'duration',
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>

                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => { onPlanTypeEdit(record) }}>
                            <FiEdit />
                        </TableIconHolder>
                        {
                            record.status === 'ACTIVE' &&
                            <Popconfirm
                                title="Change The Status"
                                description="Do you want to change the status into 'IN-ACTIVE'?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => InActivePlanType(record)}
                            >
                                <TableIconHolder color={THEME.GREEN_100} size={'22px'}>
                                    <HiOutlineBellAlert />
                                </TableIconHolder>
                            </Popconfirm>
                        }
                        {
                            record.status === 'DEACTIVE' &&
                            <Popconfirm
                                title="Change The Status"
                                description="Do you want to change the status into 'ACTIVE'?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => ActivePlanType(record)}
                            >
                                <TableIconHolder color={THEME.RED_100} size={'22px'}>
                                    <HiOutlineBellSlash />
                                </TableIconHolder>
                            </Popconfirm>
                        }


                        <CustomPopconfirm
                            record={record}
                            confirm={handleConfirm}
                            // cancel={handlePopConfrmCancel}
                            title={"Delete the Product"}
                            description={"Are you sure to delete this Plan Type ?"}>
                            <TableIconHolder color={THEME.red} size={'22px'}>
                                <MdDelete />
                            </TableIconHolder>
                        </CustomPopconfirm>
                    </Flex>
                )
            }
        },
    ];

    let content;

    if (getPlanTypeStatusDetails === 'loading') {
        content = <CommonLoading />
    } else if (getPlanTypeStatusDetails === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (getPlanTypeStatusDetails === 'failed') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    }

    return (
        <div>
            <CustomPageFormTitle Heading={'View Plan Type'} />
            <CustomCardView>
                {content}
            </CustomCardView>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}
