import React, { useEffect, useState } from 'react'
import { CustomPageFormTitle } from '../../../components/CustomPageTitle'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ViewEcomuser, ViewStatus, ViewTableuser, getEcomUser, getUser } from '../UserSlice'
import request from '../../../utils/request'
import { CustomTag } from '../../../components/Form/CustomTag'
import Flex from '../../../components/Flex'
import { TableIconHolder } from '../../../components/CommonStyled'
import { AiFillLock, AiFillUnlock } from 'react-icons/ai'
import { CommonLoading } from '../../../components/CommonLoading'
import { CustomTable } from '../../../components/Form/CustomTable'
import { THEME } from '../../../theme'
import { toast } from 'react-toastify'
import { CustomModal } from '../../../components/CustomModal'

export const EcomUserView = () => {
    const [dataSource, setDataSource] = useState([])

    const DEL_PARTY_URL = 'product/edit_unit'

    const URLE = 'user/activating_user'
    const URLD = 'user/deactivating_user'

    const navigate = useNavigate();

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);


    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);
    const [profileFormUpdate, setProfileFormUpdate] = useState(0)
    const [trigger, setTrigger] = useState(0)


    const dispatch = useDispatch()
    const ViewEcomTableData = useSelector(ViewEcomuser)
    const GetEcomStatus = useSelector(ViewStatus)

    useEffect(() => {
        dispatch(getEcomUser())
    }, [profileFormUpdate])
    useEffect(() => {
        setDataSource(ViewEcomTableData)
    }, [ViewEcomTableData])



    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        FormRest()

    };

    const handleCancel = () => {
        setIsModalOpen(false);

    };
    const FormUpdate = () => {
        setProfileFormUpdate(profileFormUpdate + 1)
    }

    const handleGetUnit = () => {
        dispatch(getUser())

    }

    const FormRest = () => {
        setFormReset(formReset + 1)
    }

    // ===== Modal Functions End =====

    const UpdateUnit = (record) => {
        setTrigger(trigger + 1)
        setModelwith(500)
        setModalTitle("Update Unit");
        // setModalContent(<AddProductUnit  ViewUnitTrigger={trigger}  unitrecord={record} handleGetUnit={handleGetUnit} UnitFormUpdate={FormUpdate} UnithandleOk={handleOk} />);
        showModal();
    };

    // ========== Delete post ==========

    const handleConfirm = (record) => {
        FormUpdate()
        DeleteUnit(record)
    }

    const handleChage = (record) => {
        Getpatch(record);
    };

    const Getpatch = (values) => {
        if (values.status === 'Disabled') {
            request.put(`${URLE}/${values.id}/`)
                .then(function (response) {
                    dispatch(getUser())
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
        else {
            request.put(`${URLD}/${values.id}/`)
                .then(function (response) {
                    dispatch(getUser())
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    }


    const DeleteUnit = (record) => {
        request.delete(`${DEL_PARTY_URL}/${record?.id}/`)
            .then((response) => {
                // dispatch(getUnit())
                toast.info("Deleted Successfully")
            }).catch(error => {
                if (error.response.status === 400) {
                    toast.error("Something Went Wrong")
                } else {
                    toast.error("Failed")
                }
            });
    };

    const columns = [
        {
            title: 'SI No',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile_number'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status, i) => {
                const displayStatus = status === 'Enabled' ? 'Enabled' : 'Disabled';
                return (
                    <>
                        <CustomTag bordered={true} color={status === 'Enabled' ? 'success' : 'error'} title={displayStatus} />
                    </>
                )
            },
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex spaceEvenly>
                        {record?.status === 'Enabled' ?
                            <TableIconHolder color={THEME.secondary_color_light} size={'22px'}>
                                <AiFillUnlock onClick={() => handleChage(record)} />
                            </TableIconHolder> : <TableIconHolder color={THEME.green} size={'22px'}>
                                <AiFillLock onClick={() => handleChage(record)} />
                            </TableIconHolder>}
                    </Flex>
                )

            }
        },
    ]





    let content;
    if (GetEcomStatus === 'loading') {
        content = <CommonLoading />
    } else if (GetEcomStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (GetEcomStatus === 'failed') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    }

    return (
        <div>
            <CustomPageFormTitle Heading={'View Ecom Users'} />
            {content}
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}
