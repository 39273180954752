import styled from "styled-components";
import { THEME } from "../../../theme";

export const StyledLoginMenu = styled.div`

max-width: 800px;
margin-top: 100px !important;
margin: auto;
background-color: #fff;

& img{
    width: 100%;
    border-radius: 10px 0px 0px 10px;

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.dDHQxW {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 5px 5px;
    margin: auto;
    max-width: 100%;
    padding: 0px !important;
    border-radius: 10px;
}

.cOGUEI {
    margin: auto;
    padding: 0px !important; 
}

.bg_Right {
    background: linear-gradient(180deg, rgba(80, 63, 131, 0.25) 0%, rgba(255, 255, 255, 0.53) 100%); 
    box-shadow: 0px 4px 10px 0px #503F8380;
}
`

export const StyledRegisterMenu = styled.div`

max-width: 800px;
margin-top: 100px !important;
margin: auto;

& img{
    width: 100%;
    border-radius: 10px 0px 0px 10px;

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.cOGUEI {
    margin: auto;
    padding: 0px !important; 
}

.dDHQxW {
    background: #e3f1ff;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 5px 5px;
    margin: auto;
    max-width: 100%;
    padding: 0px !important;
    border-radius: 10px;
}


`

export const StyledLogin = styled.div`
    padding: 20px;
    /* background: linear-gradient(180deg, rgba(80, 63, 131, 0.25) 0%, rgba(255, 255, 255, 0.53) 100%); */
    /* box-shadow: 0px 4px 10px 0px #503F8380; */
    margin: 0;
    @media screen and (max-width: 500px) {
        margin: 0;
    }
.ant-btn {
    height: 32px !important;
}

& h1 {
    color: #000;
    font-size: 20px;
    position: relative;
    margin: 30px 0;
}
& p  {
    cursor: pointer;
}
& h2 {
    color: #000;
    font-size: 18px;
    margin: 10px 0;
}
h1::before {
    content: ''; 
    position: absolute;
    width: 40px;
    height: 2px;
    background-color: #000; 
    top: 100%;
    margin: 10px 0;
}
.GGG {
    background: #503F83 !important;
}
`
export const StyledConnect = styled.div`

border-radius: 30px !important;


`
export const StyledLoginLeft = styled.div`

/* background: ${THEME.primary_2}; */
margin: auto;
display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius:10px !important;
    color: #fff;
    font-size: 20px;


& p{
    /* margin: auto; */
    /* justify-content: center;
    display: flex;
    font-family:"Space Mono", sans-serif !important;
    background-color: ${THEME.primary_2}; ;
    align-items: center;
    text-align: center;
    height: 400px;
    font-size: 20px; */
}
`
