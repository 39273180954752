import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    viewuser: [],
    viewEcomuser: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null,
}

export const getUser = createAsyncThunk(
    "Unit/Get",
    async () => {

        try {

            const response = await request.get('user/user_view_admin_panel/');
            console.log(response, 'response');
            return [...response.data];

        }

        catch (error) {
            throw error;
        }
    }
);

export const getEcomUser = createAsyncThunk(
    "EcomUser/Get",
    async () => {

        try {

            const response = await request.get('user/ecom_user_view_admin_panel/');
            return [...response.data];

        }

        catch (error) {
            throw error;
        }
    }
);


const userSlice = createSlice({
    name: 'userview',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.viewuser = action.payload;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getEcomUser.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getEcomUser.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.viewEcomuser = action.payload;
            })
            .addCase(getEcomUser.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const { setVariationsAdd, reset, addToTable } = userSlice.actions
export const ViewTableuser = (state) => state.userview.viewuser
export const ViewEcomuser = (state) => state.userview.viewEcomuser
export const ViewStatus = (state) => state.userview.status
export const ViewError = (state) => state.userview.error

export const { reducer } = userSlice;

export default userSlice.reducer


