import React, { Fragment, useEffect, useRef, useState } from 'react'
import Flex from '../../components/Flex'
import { Col, Row, Button as Buttons } from 'antd'
import { AntdStyledMenu, NavTopDraw } from './Style'
import { AiOutlineMenu } from 'react-icons/ai'
import { CustomSearchBar } from '../../components/Form/CustomSearchBar';
import { BsSearch } from 'react-icons/bs';
import Button from '../../components/Form/CustomButton'
import { useNavigate } from 'react-router-dom'
import { Menu } from 'antd/es'
import { CustomModal } from '../../components/CustomModal'
import { logOut, selectCurrentUser } from '../../modules/Auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import profile from "../../Images/profile.png"
import styled from 'styled-components'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { Notifications } from '../../modules/Notifications/Partials/Notifications'
import ProfileStyle from './ProfileStyle'
import { SyledDropDownMenu } from '../../modules/Notifications/Partials/style'
import { getNotifications, selectAllNotifications } from '../../modules/Notifications/NotificationSlice'
import { CustomBadge } from '../../components/CustomBadge'

const Profiles = styled.div`
display: flex;
align-items: center;
margin: -5px 0 20px 0px;
gap: 10px;
padding: 5px 10px;
`

const ImageProfile = styled.div`
    background-color: rgb(214 238 246);
    padding: 6px 10px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px;
    & img {
        width: 25px;
        margin:auto;
        border-radius: 50%;
    }
`
const ResponsiveContainer = styled.div`
  @media (max-width: 768px) {
    .Btnresponsive {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .btnborder {
      /* margin: 3px 0; */
      /* font-size: 1px; */
    }
  }
`;
const StyledTwoButton = styled(Flex)`
margin-right: 35px;
@media screen and (max-width: 768px) {
    display: none;
}
`
export const NavHeader = ({ updateCollapse, showDrawer }) => {

    const dispatch = useDispatch()

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [dataSource, setDataSource] = useState([])
    const profileRef = useRef(null);
    const notificationRef = useRef(null);


    const Notify = useSelector(selectAllNotifications)
    const ProfileName = useSelector(selectCurrentUser)
    const navigate = useNavigate()

    useEffect(() => {
        setDataSource(Notify)
    }, [Notify])

    useEffect(() => {
        dispatch(getNotifications())
    }, [])

    // For Profile      
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (profileRef.current && !profileRef.current.contains(e.target)) {
                // Click occurred outside the profile, so close it
                setIsProfileOpen(false);
            }
        };
        if (isProfileOpen) {
            document.addEventListener('click', handleClickOutside);
        }
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isProfileOpen]);

    // For Notification
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (notificationRef.current && !notificationRef.current.contains(e.target)) {
                // Click occurred outside the profile, so close it
                setIsNotificationOpen(false);
            }
        };
        if (isNotificationOpen) {
            document.addEventListener('click', handleClickOutside);
        }
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isNotificationOpen]);

    const toggleProfile = () => {
        setIsProfileOpen(!isProfileOpen);
    };

    const toggleNotify = () => {
        setIsNotificationOpen(!isNotificationOpen);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleClick = () => {
        navigate('/addprofile');
    };

    const AdminLogOut = () => {
        setModalContent(<LogOutModal />);
        setModalTitle("Log Out");
        showModal();
    }

    const LogOutModal = () => (
        <div>
            <h1 style={{ fontSize: '1.2rem' }}>Are you Sure You Want to Logout ?</h1>
            <br />
            <Flex gap={'20px'} W_100 center verticallyCenter>
                <Button.Success text={'Logout'} onClick={Signout} />
                <Button.Danger text={'Cancel'} onClick={handleOk} />
            </Flex>
        </div>
    )

    const Signout = () => {
        dispatch(logOut());
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const userMenu = (
        <AntdStyledMenu>
            <Menu.Item style={{ color: '#545454' }} key="1" onClick={handleClick}>My Account</Menu.Item>
            <Menu.Item style={{ color: '#545454' }} key="2" >Connections</Menu.Item>
            <Menu.Item style={{ color: '#545454' }} key="3" onClick={AdminLogOut}>Log Out</Menu.Item>
        </AntdStyledMenu>
    );

    return (
        <Fragment>
            <NavTopDraw>
                <Row>
                    <Col span={4} md={4} className='DrawBtn'>
                        <Buttons type="text" onClick={showDrawer}>
                            <AiOutlineMenu style={{ fontSize: '20px' }} />
                        </Buttons>
                    </Col>
                    <Col span={0} md={10} className='SearchTop'>
                        <Flex alignCenter spaceBetween className='SearchTop' style={{ display: 'none' }}>
                            <CustomSearchBar placeholder={'Search in app...'} prefix={<BsSearch style={{ fontSize: '16px', color: '#E1E1E1', cursor: 'pointer' }} />} />
                        </Flex>
                    </Col>
                    <Col span={20} md={14} >
                        <ResponsiveContainer>
                            <Flex flexEnd={true}>
                                <Profiles style={{ marginRight: '70px' }}>
                                    <div ref={profileRef}>
                                        <a onClick={toggleProfile} href="#open-profile">
                                            <Flex alignCenter>
                                                <ImageProfile>
                                                    <img src={profile} alt="Profile" preview={false} />
                                                </ImageProfile>
                                            </Flex>
                                        </a>
                                        {isProfileOpen && <ProfileStyle />}
                                    </div>
                                </Profiles>
                            </Flex>
                        </ResponsiveContainer>
                    </Col>
                    <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                        width={400}
                        modalTitle={modalTitle} modalContent={modalContent} />
                </Row>
            </NavTopDraw>
        </Fragment>
    )
}