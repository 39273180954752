import React from 'react'
import { CustomPageFormTitle } from '../../../components/CustomPageTitle'
import { Col, Form } from 'antd';
import { CustomCardView } from '../../../components/CustomCardView';
import { toast } from 'react-toastify';
import { CustomRow } from '../../../components/CustomRow';
import { CustomInput } from '../../../components/Form/CustomInput';
import Flex from '../../../components/Flex';
import Button from '../../../components/Form/CustomButton';
import { CustomInputPassword } from '../../../components/Form/CustomInputPassword'
import request from '../../../utils/request';

export const AddAdmin = ({ updateAdminrecord }) => {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (updateAdminrecord) {
            updateAdmin()
        } else {
            addAdmin(values)
        }
    };

    const onReset = () => {
        form.resetFields();
    };

    const addAdmin = (values) => {
        request.post('user/admin_register/',values)
            .then(resp => {
                toast.success("Admin Created Successfully!")
                onReset()
            })
            .catch(error => {
                toast.warn("User with this email already exists!")
            })
    }
    const updateAdmin = () => {

    }

    const onFinishFailed = (errorInfo) => {
        toast.error('Fill the details')
    };
    return (
        <div>
            <CustomPageFormTitle Heading={updateAdminrecord ? 'Update Admin' : 'Add Admin'} />

            <CustomCardView>
                <Form
                    name='AddAdmin'
                    form={form}
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off">

                    <CustomRow space={[12, 12]}>

                        <Col span={24} md={12}>
                            <CustomInput
                                label={'Name'}
                                placeholder={'Enter name'}
                                name={'name'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInput
                                label={'Email'}
                                placeholder={'Enter email'}
                                name={'email'}
                            />
                        </Col>
                        <Col span={24} md={12}>
                            <CustomInputPassword
                                label={'Password'}
                                placeholder={'Enter password'}
                                name={'password'}
                            />

                        </Col>
                    </CustomRow>
                    <Flex center gap={'20px'} style={{ margin: '30px' }}>
                        {updateAdminrecord ? (
                            <Button.Primary text={'UPDATE'} htmlType={'submit'} />
                        ) : (
                            <>
                                <Button.Success text={'ADD'} htmlType={'submit'} />
                                <Button.Danger text={'RESET'} onClick={onReset} />
                            </>
                        )}
                    </Flex>
                </Form>
            </CustomCardView>
        </div>
    )
}
