import { Col, Form } from 'antd'
import React, { useEffect } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInputNumber } from '../../../components/Form/CustomInputNumber'
import Button from '../../../components/Form/CustomButton'
import Flex from '../../../components/Flex'
import { toast } from 'react-toastify'
import request from '../../../utils/request'

const PlanForm = ({ handleOk, trigger, UpdateForm }) => {

    const [form] = Form.useForm();

    const ADD_POST = 'subscription/add_limited_count/'

    const onFinish = (values) => {
        PlanAdd(values)
    }

    const onFinishFailed = (errorInfo) => {
        toast.error('Fill the details')
    };

    const onReset = () => {
        form.resetFields();
    }

    useEffect(() => {
        form.resetFields()
    }, [trigger])


    const PlanAdd = (values) => {
        request.post(`${ADD_POST}`, values)
            .then(function (response) {
                toast.success("Added Successfully")
                form.resetFields();
                if (handleOk) {
                    handleOk()
                }
                if (UpdateForm) {
                    UpdateForm()
                }
            })
            .catch(function (error) {
                toast.error("Something Went Wrong")
            });
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <CustomRow space={[24, 24]}>
                <Col span={24} md={24}>
                    <CustomInputNumber label={'Count (Days)'} name={'limited_count'} placeholder={'Enter Days'}
                        rules={[
                            {
                                required: true,
                                message: 'Please fill the field',
                            },
                        ]} />
                </Col>
            </CustomRow>

            <Flex center={'true'} gap={'20px'} margin={'20px 0px'}>
                <Button.Primary text={'Save'} htmlType={'submit'} />
                <Button.Danger text={'Reset'} onClick={() => onReset()} />
            </Flex>

        </Form>
    )
}

export default PlanForm