import { combineReducers } from "redux"
import authReducer from '../modules/Auth/authSlice'
import userReducer from '../modules/Auth/googleSlice'
import emailReducer from '../modules/Auth/googleSlice'
import NotificationReducer from '../modules/Notifications/NotificationSlice'
import AdminPanelReducer from '../modules/AdminPanel/PanelSlice'
import userViewReducer from '../modules/UserView/UserSlice'


const rootReducer = combineReducers({
    auth:authReducer,
    email:emailReducer,
    user: userReducer,
    notifications:NotificationReducer,
    adminpanel:AdminPanelReducer,
    userview:userViewReducer,
})

export default rootReducer;