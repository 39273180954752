

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    addplantype: [],
    addplan: [],
    freeplan: [],
    userdetails: [],
    admins: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

// ======   plantype    =========
export const getPlanType = createAsyncThunk(
    "AddPlanType/Get",
    async () => {
        try {
            const response = await request.get('subscription/add_plans_type/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

// ======   plan    =========
export const getPlan = createAsyncThunk(
    "AddPlan/Get",
    async () => {
        try {
            const response = await request.get('subscription/add_plans/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

// ======   FteeTriel    =========
export const getFreePlan = createAsyncThunk(
    "FreePlan/Get",
    async () => {
        try {
            const response = await request.get('subscription/add_limited_count/');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

// ======   getAdmin    =========
export const getAdmins = createAsyncThunk(
    "admin/Get",
    async () => {
        try {
            const response = await request.get('user/users_view');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

// ======   User Details    =========
export const getUserDetails = createAsyncThunk(
    "FreePlan/Get",
    async () => {
        try {
            const response = await request.get('');
            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const AdminPanelReducer = createSlice({
    name: 'AdminPanel',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPlanType.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getPlanType.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.addplantype = action.payload;
            })
            .addCase(getPlanType.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(getPlan.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getPlan.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.addplan = action.payload;
            })
            .addCase(getPlan.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })

            .addCase(getFreePlan.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getFreePlan.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.freeplan = action.payload;
            })
            .addCase(getFreePlan.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })

            .addCase(getAdmins.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getAdmins.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.admins = action.payload;
            })
            .addCase(getAdmins.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    
    }
})


export const getAllPlanType = (state) => state.adminpanel.addplantype
export const getAllPlanTypeStatus = (state) => state.adminpanel.status
export const getAllPlanTypeError = (state) => state.adminpanel.error
export const getAllPlans = (state) => state.adminpanel.addplan
export const getAllPlanStatus = (state) => state.adminpanel.status
export const getAllPlanError = (state) => state.adminpanel.error

export const AllFreePlan = (state) => state.adminpanel.freeplan
export const FreePlantatus = (state) => state.adminpanel.status
export const FreePlanError = (state) => state.adminpanel.error

export const getAllAdmins = (state) => state.adminpanel.admins

export const { reducer } = AdminPanelReducer

export default AdminPanelReducer.reducer
