import React, { useEffect, useState } from 'react'
import { CustomCardView } from '../../../components/CustomCardView'
import { CustomModal } from '../../../components/CustomModal'
import { CustomTable } from '../../../components/Form/CustomTable';
import Flex from '../../../components/Flex';
import Button from '../../../components/Form/CustomButton';
import PlanForm from './PlanForm';
import { CustomSwitch } from '../../../components/Form/CustomSwitch';
import request from '../../../utils/request';

const TableDatas = () => {


    const CLICKED_URL = 'subscription/activating_limited_count_action/'

    const [trigger, setTrigger] = useState(0)

    const [ModalWidth, setModalWidth] = useState(0)

    const [DataSource, setDataSource] = useState([])

    const switchValue = DataSource[0]?.limited_count_action === true ? true : false;

    const [clickedValue, setClickedValue] = useState(switchValue);

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const UpdateForm = () => {
        UpdateGET()
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const AddPlanForm = () => {
        setTrigger(trigger + 1)
        setModalTitle("Add Plan Count");
        setModalWidth(400)
        setModalContent(<PlanForm handleOk={handleOk} UpdateForm={UpdateForm} trigger={trigger} />);
        showModal();
    }

    const handleChanages = (values) => {


        setClickedValue(values);

        const newData = { ...values, limited_count_status: values === true ? 'ON' : 'OFF' }
        ClickedPut(newData)
    }


    useEffect(() => {
        UpdateGET()
    }, [])

    useEffect(() => {

        if (DataSource[0]?.limited_count_action === true) {
            setClickedValue(true)

        } else {
            setClickedValue(false)
        }

    }, [DataSource])


    const UpdateGET = () => {
        request.get('subscription/add_limited_count/')
            .then(function (response) {
                setDataSource(response.data)
            })
            .catch(function (error) {
            });
    }

    const ClickedPut = (values) => {
        request.post(`${CLICKED_URL}`, values)
            .then(function (response) {
            })
            .catch(function (error) {
            });
    }

    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Free Trial Count',
            dataIndex: 'limited_count',
        },
        {
            title: 'Status',
            render: (values, record) => {
                return (
                    <>
                        {
                            clickedValue ? <p>Active</p> : <p>Deactive</p>
                        }
                    </>
                )
            }
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        <CustomSwitch onChange={handleChanages} checked={clickedValue} />
                    </Flex>
                )
            }
        },
    ];

    // let content;

    // if (TrialFreePlantatus === 'loading') {
    //     content = <CommonLoading />
    // } else if (TrialFreePlantatus === 'succeeded') {
    //     const rowKey = (dataSource) => dataSource.id;
    //     content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    // } else if (TrialFreePlantatus === 'failed') {
    //     const rowKey = (dataSource) => dataSource.id;
    //     content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    // }

    return (
        <div>
            <Flex end={true}>
                <Button.Primary text={'Add Plan'} onClick={() => AddPlanForm()} />
            </Flex><br />
            <CustomCardView>
                <CustomTable columns={columns} data={DataSource} />
            </CustomCardView>
            <CustomModal isVisible={isModalOpen} width={ModalWidth} handleOk={handleOk} handleCancel={handleCancel} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default TableDatas