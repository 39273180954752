import React from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { Col } from 'antd'
import Flex from '../../../components/Flex'

export const ViewPaymentRecord = (record) => {
    return (
        <div>
            <CustomRow space={[24, 24]}>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Order Id</p>
                        <p>{record?.order_id}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Tracking Id</p>
                        <p>{record?.tracking_id}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Amount</p>
                        <p>{record?.amount}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Bank Reference Number</p>
                        <p>{record?.bank_ref_no}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Billing Address</p>
                        <p>{record?.billing_address}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Billing City</p>
                        <p>{record?.billing_city}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Billing Country</p>
                        <p>{record?.billing_country}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Billing Email</p>
                        <p>{record?.billing_email}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Billing Name</p>
                        <p>{record?.billing_name}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Billing State</p>
                        <p>{record?.billing_state}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Billing Phone Number</p>
                        <p>{record?.billing_tel}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Billing Zip</p>
                        <p>{record?.billing_zip}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Card Name</p>
                        <p>{record?.card_name}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Currency</p>
                        <p>{record?.currency}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Delivery Address</p>
                        <p>{record?.delivery_address}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Delivery City</p>
                        <p>{record?.delivery_city}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Delivery Country</p>
                        <p>{record?.delivery_country}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Delivery Name</p>
                        <p>{record?.delivery_name}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Delivery State</p>
                        <p>{record?.delivery_state}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Delivery PhoneNumber</p>
                        <p>{record?.delivery_tel}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Delivery Zip</p>
                        <p>{record?.delivery_zip}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Merchant Amount</p>
                        <p>{record?.mer_amount}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Order Status</p>
                        <p>{record?.order_status}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Payment Mode</p>
                        <p>{record?.payment_mode}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Status Message</p>
                        <p>{record?.status_message}</p>
                    </Flex>
                </Col>
                <Col span={24} >
                    <Flex spaceBetween>
                        <p style={{fontWeight:'bold' ,fontSize:16}}>Transaction Date</p>
                        <p>{record?.trans_date}</p>
                    </Flex>
                </Col>
            </CustomRow>
        </div>
    )
}
