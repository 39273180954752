import React, { useState } from 'react'
import { CustomPageFormTitle, CustomPageTitle } from '../../../components/CustomPageTitle'
import { useParams } from 'react-router-dom';
import Flex from '../../../components/Flex';
import { TableIconHolder } from '../../../components/CommonStyled';
import { IoEye } from "react-icons/io5";
import { THEME } from '../../../theme';
import { CustomTable } from '../../../components/Form/CustomTable';
import { CustomModal } from '../../../components/CustomModal';
import { ViewPaymentRecord } from './ViewPaymentRecord';
import request from '../../../utils/request';
import { useEffect } from 'react';

export const PaymentRecord = () => {

    const id = useParams()
    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [datasource, setDatasource] = useState([])

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);
    const [paymentRecordUpdate, setPaymentRecordUpdate] = useState(0)

    useEffect(() => {
        request.get(`gate_way/view_user_payment/${id.id}/`)
            .then(resp => {
                setDatasource(resp.data)
            })
            .catch(error => console.log('error', error))
    }, [id])



    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
        FormUpdate()
    };
    const handleOk = () => {
        setIsModalOpen(false);
        RestForm()
        FormUpdate()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        RestForm()
    };
    const FormUpdate = () => {
        setPaymentRecordUpdate(paymentRecordUpdate + 1)
    }
    const RestForm = () => {
        setFormReset(formReset + 1)
    }

    const handleView = (record) => {
        setPaymentRecordUpdate(paymentRecordUpdate + 1)
        setModelwith(800)
        setModalTitle("View Payment Record");
        setModalContent(<ViewPaymentRecord record={record} />);
        showModal();
    }

    const tableColumns = [
        {
            title: 'S.No',
            render: (text, record, index) => {
                return (
                    <h4>{index + 1}</h4>
                )
            },
        },
        {
            title: 'Date',
            dataIndex: 'date'
        },
        {
            title: 'Order No',
            dataIndex: 'order_id'
        },
        {
            title: 'Tracking Id',
            dataIndex: 'tracking_id'
        },
        {
            title: 'Amount',
            dataIndex: 'amount'
        },
        {
            title: 'Order Status',
            dataIndex: 'order_status'
        },
        {
            title: 'Payment Mode',
            dataIndex: 'payment_mode'
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center gap={'10px'}>
                        <TableIconHolder color={THEME.yellow} size={'22px'}>
                            <IoEye onClick={() => handleView(record)} />
                        </TableIconHolder>

                        {/* <CustomPopconfirm
                                record={record}
                                title={"Delete the Stock List"}
                                description={"Are you sure to delete this Stock ?"}>
                                <TableIconHolder size={'28px'}>
                                    <img src={SvgIcons.Delete} alt="delete" />
                                </TableIconHolder>
                            </CustomPopconfirm> */}
                    </Flex>
                )
            }
        },

    ]
    return (
        <div>
            <CustomPageTitle Heading={'Payment Record'} />
            <CustomTable columns={tableColumns} data={datasource} />
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}
