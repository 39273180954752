import { Menu } from "antd";
import { useNavigate } from 'react-router-dom';
import { AiFillDiff, AiOutlineCrown, AiOutlineWeibo } from "react-icons/ai";
import { FaFreeCodeCamp, FaUserTie } from 'react-icons/fa'
import { useState } from "react";
import { BsFillClipboard2PlusFill } from "react-icons/bs";
import styled from "styled-components";
import profile from "../../Images/logshow.png"
import { useDispatch, useSelector } from "react-redux";
import { LiaEyeSolid } from 'react-icons/lia'
import Flex from "../../components/Flex";
import { MdOutlineStreetview } from 'react-icons/md'
import Button from "../../components/Form/CustomButton";
import { CustomModal } from "../../components/CustomModal";
import { logOut, selectCurrentUser } from "../../modules/Auth/authSlice";
import { FaUsersViewfinder } from "react-icons/fa6";
import { GrUserAdmin } from "react-icons/gr";

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const Profile = styled.div`
display: flex;
align-items: center;
margin: 20px 0 10px 0px;
gap: 10px;
padding: 5px 20px;
`

const ImageProfile = styled.div`
/* width:100%; */
    & img {
        width: 40px;
        margin:auto;
        border-radius: 50%;
        border: 1px solid #dededec7;
    }
`

const MenuText = styled.div`
   font-size: 16px;
   color: #545454;
   padding-left: 7px;
`

export const SideMenu = ({ collapsed }) => {


    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const dispatch = useDispatch()


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const rootSubinmenuKeys = ['sub1', 'sub2'];


    const [openKeys, setOpenKeys] = useState(['sub1']);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubinmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const ProfileName = useSelector(selectCurrentUser)

    const items = [
        getItem('Admin Panel', ''),
        getItem('Subscription', 'sub1', <AiOutlineCrown />, [
            getItem('Add Plan Type', 'add_plan_type', <BsFillClipboard2PlusFill />),
            getItem('View Plan Type', 'view_plan_type', <LiaEyeSolid />),
            getItem('Add Plan', 'add_plan', <AiFillDiff />),
            getItem('View Plan', 'view_plan', <AiOutlineWeibo />),
            getItem('Free Trial', 'freetrial', <FaFreeCodeCamp />),
            getItem('User View', 'user_view', <MdOutlineStreetview />),
            getItem('Ecom User View', 'ecom_user_view', <MdOutlineStreetview />),
            getItem('Add Admin', 'add_admin', <GrUserAdmin />),
            getItem('View Admin', 'view_admin', <FaUsersViewfinder />),
        ]),
    ];

    const navigate = useNavigate();

    const onClick = ({ key }) => {

        if (key === null) {
        }
        else {
            navigate(`${key}/`)
        }
    }


    const handleOk = () => {
        setIsModalOpen(false);
    };


    const SearchDatas = styled.div`
    display: flex;
    align-items: center;
    gap: 35px;
    margin-left: 30px;
    color: #038fe0;
    `

    const Signout = () => {
        dispatch(logOut());

    }


    const LogOutModal = () => (
        <div>
            <h1 style={{ fontSize: '1.2rem' }}>Are you Sure You Want to Logout ?</h1>
            <br />
            <Flex gap={'20px'} W_100 center verticallyCenter>
                <Button.Success text={'Logout'} onClick={Signout} />
                <Button.Danger text={'Cancel'} onClick={handleOk} />
            </Flex>
        </div>
    )

    const handleClick = () => {
        navigate('/addprofile');
    };


    const AdminLogOut = () => {
        setModalContent(<LogOutModal />);
        setModalTitle("Log Out");
        showModal();
    }

    // const userMenu = (
    //     <AntdStyledMenu>
    //         <Menu.Item style={{ color: '#545454' }} key="1" onClick={handleClick}>My Account</Menu.Item>
    //         <Menu.Item style={{ color: '#545454' }} key="2" >Connections</Menu.Item>
    //         <Menu.Item style={{ color: '#545454' }} key="3" onClick={AdminLogOut}>Log Out</Menu.Item>
    //     </AntdStyledMenu>
    // );

    return (
        <>
            <Profile>
                <Flex alignCenter>
                    <ImageProfile>
                        <img src={profile} alt="Profile" preview={false} />
                    </ImageProfile>
                    {!collapsed && (
                        <>
                            <p
                                style={{
                                    transition: '0.5s',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    lineHeight: '1.3',
                                    textTransform: 'capitalize',
                                }}
                            >
                                &nbsp;&nbsp;&nbsp;&nbsp;{ProfileName}
                            </p>
                            &nbsp;
                        </>
                    )}

                </Flex>
            </Profile>
            <div style={{ minHeight: 'calc(100vh - 250px)', overflowY: 'auto', position: 'relative' }}>

                <Menu style={{ color: '#545454', position: 'absolute', width: '100%' }}
                    onClick={onClick}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    items={items}
                />
            </div>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                width={400}
                modalTitle={modalTitle} modalContent={modalContent} />
        </>
    )
}

